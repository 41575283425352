import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_4/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-4-2"}}}) {
      edges {
        node {
          frontmatter {
            author
            authorInfo
            title
          }
          body
        }
      }
    }
    file(relativePath: { eq: "chapter_4/sub_4/4-4-2-bg__mobile.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
